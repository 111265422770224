import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import VideoSection from "../sections/videoTutorial"

function VideoTutorial() {
  return (
    <Layout>
      <SEO title="Tutorial" />
      <VideoSection />
    </Layout>
  );
}

export default VideoTutorial;